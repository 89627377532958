<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page2">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_shadow m_8">
                  <div class="box box_one">
                    <div class="box_list_wrap">
                      <div class="box_list">
                        <div class="list_ttl">
                          <div class="left">
                            <div class="m_4">
                              <span class="build_name">{{ buildingName }}</span>
                            </div>
                            <p class="white_wrap ft_m_16">{{ form.title }}</p>
                            <div class="price_box">
                              <span class="price_txt">{{
                                $dayjs(form.regDate).format(
                                  'YYYY.MM.DD HH:mm:ss'
                                )
                              }}</span>
                            </div>
                          </div>
                          <div class="middle_box flex1none">
                            <div class="middle">
                              <p class="price r_12 f_g">{{ unit }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line_grey none"></div>
                    </div>
                    <div class="text_box editor">
                      <span>{{ form.contents }}</span>
                      <div class="img_box">
                        <img
                          v-for="(item, index) in filePaths"
                          :key="index"
                          :src="
                            $ConstCode.getImagePath(item.imagePath, 'origin/')
                          "
                          alt="img"
                          @click="fnPopUp(item.imagePath)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="userNo === form.userNo">
          <div class="btn_wrap">
            <div class="inline">
              <button class="btn left" @click="fnDelete(form.resNo)">
                삭제하기
              </button>
              <button class="btn on" @click="fnEdit(form.resNo)">
                수정하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'

export default {
  name: 'menu-notice-tenant-view',
  description: '입주민 소통공간 상세페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const query = route.query

    const userData = computed(() => store.getters['user/getData'])

    const state = reactive({
      buildingName: userData.value.buildingName,
      unit: userData.value.unitName,
      userNo: userData.value.userNo,
      form: {
        resNo: -1,
        title: '',
        contents: '',
        regDate: ''
      },
      filePaths: []
    })

    const fetchData = async () => {
      const res = await proxy.$ResNoticeSvc.fetchResNotice(query)
      if (res.code === 1) {
        state.form = res.entity.res
        state.unit = res.entity.unit
        state.filePaths = res.entity.filePaths
      }
    }

    const fnEdit = item => {
      router.push({
        path: '/menu/notice/tenant/write',
        query: { resNo: item, unit: state.unit }
      })
    }

    const fnDelete = async item => {
      if (!confirm('삭제하시겠습니까?')) return
      const res = await proxy.$ResNoticeSvc.deleteResNotice({ resNo: item })
      if (res.code === 1) {
        alert('삭제되었습니다.')
        router.go(-1)
      }
    }

    const fnPopUp = async path => {
      const payload = {}
      payload.component = proxy.$modalAlertNames.IMAGE
      payload.layout = {
        imagePath: path
      }
      payload.callBack = proxy => {
        proxy.$emit('close')
      }
      await store.dispatch('layout/pushModalAlertComponent', payload)
    }

    fetchData()
    return { ...toRefs(state), fetchData, fnEdit, fnDelete, fnPopUp }
  }
}
</script>

<style scoped></style>
